
  import MyCheckWorldCheckResult from '~/components/marketplace/my_checks/MyCheckWorldCheckResult.vue'
  import MyCheckCreditReformResult from '~/components/marketplace/my_checks/MyCheckCreditReformResult.vue'
  import MyCheckDefaultResult from '~/components/marketplace/my_checks/MyCheckDefaultResult.vue'
  import MyCheckIDResult from '~/components/marketplace/my_checks/MyCheckIDResult.vue'
  import MyCheckSwissCriminalRecordResult from '~/components/marketplace/my_checks/MyCheckSwissCriminalRecordResult.vue'
  import MyCheckInternetProfileResult from '~/components/marketplace/my_checks/MyCheckInternetProfileResult.vue'
  import MyCheckMedicalTestResult from '~/components/marketplace/my_checks/MyCheckMedicalTestResult.vue'
  import MyCheckSedexCheckResult from '~/components/marketplace/my_checks/MyCheckSedexCheckResult.vue'
  import MyCheckCrifResult from '~/components/marketplace/my_checks/MyCheckCrifResult.vue'

  export default {
    name: `MyCheckResultFactory`,
    components: {
      MyCheckWorldCheckResult,
      MyCheckCreditReformResult,
      MyCheckDefaultResult,
      MyCheckIDResult,
      MyCheckSwissCriminalRecordResult,
      MyCheckInternetProfileResult,
      MyCheckMedicalTestResult,
      MyCheckSedexCheckResult,
      MyCheckCrifResult
    },
    data() {
      return {
        componentByType: {
          "world_check": MyCheckWorldCheckResult,
          "credit_reform": MyCheckCreditReformResult,
          "identt": MyCheckIDResult,
          "swiss_criminal_record": MyCheckSwissCriminalRecordResult,
          "internet_profile": MyCheckInternetProfileResult,
          "medical_test": MyCheckMedicalTestResult,
          "sedex_check": MyCheckSedexCheckResult,
          "crif": MyCheckCrifResult,
          "default": MyCheckDefaultResult
        }
      }
    },
    props: {
      myCheck: {
        type: Object,
        default: undefined
      },
    },
    computed: {
      component(){
        return Object.keys(this.componentByType).includes(this.myCheck?.type?.check_code)
          ? this.componentByType[this.myCheck.type?.check_code]
          : this.componentByType.default
      }
    }
  }
